import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';

import Menuusuario from '../../../componentes/MenuFuncinario';
import Footer from '../../../componentes/Footer/index';

import { format, startOfDay, isBefore } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import DatePicker from 'react-datepicker';

import config from '../../../services/config';

import api from '../../../services/api';
import { Container, Botoes } from './styles';

import { useAlert } from 'react-alert'

function Relatorios({ history, sistema, escola, usuario, periodo }) {

  const alert = useAlert();

  const [disableDate, setDisableDate] = useState(true);
  const [disableFiltro, setDisableFiltro] = useState(true);
  const [disableAva, setDisableAva] = useState(false);
  const [avaliacoes, setAvaliacoes] = useState([]);
  const [turmas, setTurmas] = useState([]);
  const [turmaDetalhe, setTurmaDetalhe] = useState([]);
  const [disciplinas, setDisciplinas] = useState([]);
  const [materias, setMaterias] = useState([]);
  const [dados, setDados] = useState({
    nava: '',
    ava: '',
    turma: '',
    disciplina: '',
    materia: '',
    tipo: 'LISTA',
    ordem: 'numero',
    matriculado: 'N',
  });

  const [turma, setTurma] = useState(null);
  const [disciplina, setDisciplina] = useState(null);
  const [materia, setMateria] = useState(null);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [disableDiscip, setDisableDiscip] = useState(false);

  async function loadAvaliacoes() { // avaliações

    const result = await api.get('avaliacoes/diarioweb', {
      params: {
        codigo: escola.codigo,
        escola: periodo.escola,
        ano: periodo.ano,
        seqano: periodo.seqano,
      },
    });
    setAvaliacoes(result.data);
  }
  // eslint-disable-next-line
  useEffect(() => { loadAvaliacoes() }, [periodo]);

  // carrega turmas
  useEffect(() => {
    async function load() {
      let url = '/grade/turmas/diario';

      if (usuario.tipo === 'ADMIN')
        url = '/grade/turmas/diario/admin';

      if (usuario.tipo === 'COORD')
        url = '/grade/turmas/diario/coord';

      const result = await api.get(url, {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          guerra: usuario.guerra,
        },
      });
      setTurmas(result.data);
      // setListaTurmas(result.data);
    }

    load();
  }, [usuario, escola, periodo])

  // carrega disciplinas
  useEffect(() => {
    async function load() {
      let url = '/grade/disciplinas/diario';

      if (usuario.tipo === 'ADMIN')
        url = '/grade/disciplinas/diario/todas';

      if (usuario.tipo === 'COORD')
        url = '/grade/disciplinas/diario/coord';

      const result = await api.get(url, {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          guerra: usuario.guerra,
          turma: turma.turma,
        },
      });
      setDisciplinas(result.data);
    }

    if (turma)
      load();

  }, [usuario, escola, periodo, turma])

  function changeTurma(e) {
    const reg = turmas.find(t => t.turma === e.target.value)
    setTurma(reg);
    setDados({ ...dados, turma: e.target.value, disciplina: '' });
    setDisciplina(null);
  }

  function handleChange(e) {

    if (e.target.name === 'nava')
      setDados({ ...dados, nava: e.target.value, ava: e.target.options[e.target.selectedIndex].text });

    if (e.target.name === 'turma')
      setDados({ ...dados, turma: e.target.value });

    if (e.target.name === 'disciplina')
      setDados({ ...dados, disciplina: e.target.value });

    if (e.target.name === 'materia')
      setDados({ ...dados, materia: e.target.value });
  }

  useEffect(() => {
    console.log('carregamateria');
    async function carregaMaterias() {
      let url = '/grade/materias';

      if (usuario.tipo === 'ADMIN')
        url = '/grade/materias/admin';

      if (usuario.tipo === 'COORD')
        url = '/grade/materias/admin';

      const resultMateria = await api.get(url, {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          guerra: usuario.guerra,
          turma: dados.turma,
          discip: dados.disciplina,
          nava: dados.nava,
        },
      });
      console.log(resultMateria.data);
      setMateria(resultMateria.data.length > 0 ? 'S' : 'N');
      setMaterias(resultMateria.data.length > 0 ? resultMateria.data : []);
    }

    if (dados.disciplina)
      carregaMaterias();

  }, [dados.disciplina]);

  function handleChangeTipo(e) {
    setDados({ ...dados, tipo: e.target.value });

    if ('LISTA, NOTAS, MEDIAGERAL, BOLETIM'.includes(e.target.value)) {
      setDisableDate(true);
    } else {
      setDisableDate(false);
    }

    if ('MEDIAGERAL, BOLETIM'.includes(e.target.value))
      setDisableAva(true)
    else
      setDisableAva(false)

    if (e.target.value == 'FREQGLOBAL')
      setDisableDiscip(true)
    else
      setDisableDiscip(false)

  }

  async function gerarRelatorio() {
    try {
      const params = '?codigo=' + escola.codigo +
        '&escola=' + periodo.escola +
        '&ano=' + periodo.ano +
        '&seqano=' + periodo.seqano +
        '&turma=' + dados.turma +
        '&discip=' + dados.disciplina +
        '&materia=' + dados.materia +
        '&nava=' + dados.nava +
        '&ava=' + dados.ava +
        '&ordem=' + dados.ordem +
        '&matriculado=' + dados.matriculado +
        '&dataini=' + format(startDate, 'yyyy-MM-dd') +
        '&datafim=' + format(endDate, 'yyyy-MM-dd');

      switch (dados.tipo) {
        case 'LISTA':
          window.open(config.urlRelatorios + 'lista.php' + params);
          break;
        case 'NOTAS':
          window.open(config.urlRelatorios + 'notas.php' + params);
          break;
        case 'FREQ':
          window.open(config.urlRelatorios + 'frequencia_dia.php' + params);
          break;
        case 'FREQGLOBAL':
          window.open(config.urlRelatorios + 'frequencia_global.php' + params);
          break;
        case 'CONTEUDO':
          window.open(config.urlRelatorios + 'conteudo.php' + params);
          break;
        case 'MEDIAGERAL':
          window.open(config.urlRelatorios + 'notageral.php' + params);
          break;
        case 'BOLETIM':
          window.open(config.urlRelatorios + 'notaboletim.php' + params);
          break;
        default:
          break;
      }
      alert.success('Relatório gerado com sucesso!');
    } catch (error) {
      alert.error(error);
    }
  }

  const CustomDatePciker = React.forwardRef((props, ref) => (
    <button type="button" className={"form-control " + (disableDate && 'disabled')} onClick={props.onClick} >
      {props.value}
    </button>
  ));

  return (
    <Container>
      <Menuusuario history={history} />
      <Card className="card-main">
        <Card.Body>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">Relatórios</li>
            </ol>
          </nav>

          <hr />

          <form>
            {sistema.isMobile ? (
              <>
                <div className="form-group">
                  <label >Relatório</label>
                  <select className="form-control" name="tipo" defaultValue={dados.tipo} onChange={handleChangeTipo}>
                    <option key={'LISTA'} value={'LISTA'}>LISTAGEM DE ALUNOS</option>
                    <option key={'NOTAS'} value={'NOTAS'}>NOTAS / AVALIAÇÃO</option>
                    <option key={'MEDIAGERAL'} value={'MEDIAGERAL'}>NOTAS GERAL</option>
                    <option key={'BOLETIM'} value={'BOLETIM'}>NOTAS BOLETIM</option>
                    <option key={'FREQ'} value={'FREQ'}>FREQUÊNCIA</option>
                    <option key={'FREQGLOBAL'} value={'FREQGLOBAL'}>FREQUÊNCIA GLOBAL</option>
                    <option key={'CONTEUDO'} value={'CONTEUDO'}>CONTEÚDO PROG.</option>
                  </select>
                </div>
                <div className="form-group">
                  <label >Avaliação</label>
                  <select className="form-control" disabled={!disableDate || disableAva} id="nava" name="nava" defaultValue={dados.nava} onChange={handleChange}>
                    <option value="">...</option>
                    {avaliacoes.map(item =>
                      <option key={item.id} value={item.nava}>{item.ava}</option>
                    )}
                  </select>
                </div>
                <div className="form-group">
                  <label >Turma</label>
                  <select className="form-control" name="turma" defaultValue={dados.turma} onChange={changeTurma} >
                    <option value="">...</option>
                    {turmas.map(item =>
                      <option key={item.turma} value={item.turma}>{item.turma} - {item.destur}</option>
                    )}
                  </select>
                </div>

                <div className="form-group">
                  <label>Disciplina</label>
                  <select className="form-control" disabled={disableDiscip} value={dados.disciplina} name="disciplina" onChange={handleChange}>
                    <option value="">...</option>
                    {disciplinas.map((item, index) =>
                      <option key={index} value={item.discip}>{item.discip} - {item.desdis}</option>
                    )}
                  </select>
                </div>

                {materias.length > 0 && (
                  <div className="form-group">
                    <label >Matéria</label>
                    <select className="form-control" value={dados.materia} name="materia" onChange={handleChange}>
                      <option value="">...</option>
                      {materias.map((item, index) =>
                        <option key={index} value={item.materia}>{item.desdis}</option>
                      )}
                    </select>
                  </div>
                )}

                <div className="form-group ">
                  <label >Período</label>
                  <DatePicker
                    disabled={disableDate}
                    name="startDate"
                    locale={pt}
                    selected={startDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={date => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    customInput={<CustomDatePciker />}
                  />
                  {/* <DatePicker
                    name="startDate"
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    selected={startDate}
                    onChange={inputDateChange}
                    customInput={<CustomDatePciker />}
                    locale={pt} /> */}

                  {/* <label className="col-md-2 col-form-label text-rigth">Data</label> */}
                  &emsp;
                  <DatePicker
                    disabled={disableDate}
                    name="endDate"
                    dateFormat="dd/MM/yyyy"
                    selected={endDate}
                    onChange={date => setEndDate(date)}
                    //maxDate={new Date()}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    locale={pt}
                    customInput={<CustomDatePciker />}
                  />
                  {/* <DatePicker
                    name="endDate"
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    selected={endDate}
                    onChange={inputDateChange}
                    customInput={<CustomDatePciker />}
                    locale={pt} /> */}
                </div>
                <div className="form-group ">
                  <label >Ordenar por</label>
                  <div className="form-check check-ordem">
                    <input className="form-check-input" disabled={!disableDate} type="radio" name="ordem" onChange={e => setDados({ ...dados, ordem: 'numero' })} checked={dados.ordem === 'numero'} />
                    <label className="form-check-label" >
                      Número de Ordem
                    </label>
                  </div>
                  <div className="form-check check-ordem">
                    <input className="form-check-input" disabled={!disableDate} type="radio" name="ordem" onChange={e => setDados({ ...dados, ordem: 'nome' })} checked={dados.ordem === 'nome'} />
                    <label className="form-check-label">
                      Nome
                    </label>
                  </div>

                </div>

                <div className="form-group ">
                  <label >Somente matrículados</label>
                  <div className="form-check check-ordem">
                    <input className="form-check-input" disabled={!disableFiltro} type="checkbox" name="matriculados" onChange={e => setDados({ ...dados, matriculados: 'S' })} checked={dados.matriculados === 'S'} />
                  </div>
                </div>

                <div className="form-group">
                  <button type="button" className="btn btn-dark btn-block" onClick={() => gerarRelatorio()}>Gerar Relatório</button>
                </div>

              </>
            ) : (
              <>
                <div className="form-group row">
                  <label className="col-md-2 col-form-label text-right">Relatório</label>
                  <select className="form-control col-md-4" name="tipo" defaultValue={dados.tipo} onChange={handleChangeTipo}>
                    <option key={'LISTA'} value={'LISTA'}>LISTAGEM DE ALUNOS</option>
                    <option key={'NOTAS'} value={'NOTAS'}>NOTAS / AVALIAÇÃO</option>
                    <option key={'MEDIAGERAL'} value={'MEDIAGERAL'}>NOTAS GERAL</option>
                    <option key={'BOLETIM'} value={'BOLETIM'}>NOTAS BOLETIM</option>
                    <option key={'FREQ'} value={'FREQ'}>FREQUÊNCIA</option>
                    <option key={'FREQGLOBAL'} value={'FREQGLOBAL'}>FREQUÊNCIA GLOBAL</option>
                    <option key={'CONTEUDO'} value={'CONTEUDO'}>CONTEÚDO PROG.</option>
                  </select>
                </div>
                <div className="form-group row">
                  <label className="col-md-2 col-form-label text-right">Avaliação</label>
                  <select className="form-control col-md-3" disabled={!disableDate || disableAva} id="nava" name="nava" defaultValue={dados.nava} onChange={handleChange}>
                    <option value="">...</option>
                    {avaliacoes.map(item =>
                      <option key={item.id} value={item.nava}>{item.ava}</option>
                    )}
                  </select>
                </div>
                <div className="form-group row">
                  <label className="col-md-2 col-form-label text-right">Turma</label>
                  <select className="form-control col-md-7" name="turma" defaultValue={dados.turma} onChange={changeTurma} >
                    <option value="">...</option>
                    {turmas.map(item =>
                      <option key={item.turma} value={item.turma}>{item.turma} - {item.destur}</option>
                    )}
                  </select>
                </div>

                <div className="form-group row">
                  <label className="col-md-2 col-form-label text-right">Disciplina</label>
                  <select className="form-control col-md-4" disabled={disableDiscip} value={dados.disciplina} name="disciplina" onChange={handleChange}>
                    <option value="">...</option>
                    {disciplinas.map((item, index) =>
                      <option key={index} value={item.discip}>{item.discip} - {item.desdis}</option>
                    )}
                  </select>
                </div>

                <div className="form-group row">

                  {materias.length > 0 && (
                    <>
                      <label className="col-md-2 col-form-label text-rigth">Matéria</label>
                      <select className="form-control col-md-4" name="materia" value={dados.materia} onChange={handleChange}>
                        <option value="">...</option>
                        {materias.map((item, index) =>
                          <option key={index} value={item.materia}>{item.desdis}</option>
                        )}
                      </select>
                    </>
                  )}
                </div>

                <div className="form-group row">
                  <label className="col-md-2 col-form-label text-rigth">Período</label>
                  <DatePicker
                    disabled={disableDate}
                    name="startDate"
                    locale={pt}
                    selected={startDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={date => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    customInput={<CustomDatePciker />}
                  />
                  {/* <DatePicker
                    name="startDate"
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    selected={startDate}
                    onChange={inputDateChange}
                    customInput={<CustomDatePciker />}
                    locale={pt} /> */}

                  {/* <label className="col-md-2 col-form-label text-rigth">Data</label> */}
                  &emsp;
                  <DatePicker
                    disabled={disableDate}
                    name="endDate"
                    dateFormat="dd/MM/yyyy"
                    selected={endDate}
                    onChange={date => setEndDate(date)}
                    // maxDate={new Date()}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    locale={pt}
                    customInput={<CustomDatePciker />}
                  />
                  {/* <DatePicker
                    name="endDate"
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    selected={endDate}
                    onChange={inputDateChange}
                    customInput={<CustomDatePciker />}
                    locale={pt} /> */}
                </div>
                <div className="form-group row">
                  <label className="col-md-2 col-form-label text-rigth">Ordenar por</label>
                  <div className="form-check check-ordem">
                    <input className="form-check-input" type="radio" name="ordem" onChange={e => setDados({ ...dados, ordem: 'numero' })} checked={dados.ordem === 'numero'} />
                    <label className="form-check-label" >
                      Número de Ordem
                    </label>
                  </div>
                  <div className="form-check check-ordem">
                    <input className="form-check-input" type="radio" name="ordem" onChange={e => setDados({ ...dados, ordem: 'nome' })} checked={dados.ordem === 'nome'} />
                    <label className="form-check-label">
                      Nome
                    </label>
                  </div>

                </div>

                <div className="form-group row">
                  <label className="col-md-2 col-form-label text-rigth">Somente matriculados</label>
                  <div className="form-check check-ordem">
                    <input className="form-check-input" disabled={!disableFiltro} type="checkbox" name="matriculado" onChange={e => setDados({ ...dados, matriculado: e.target.checked ? 'S' : 'N' })} />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-md-2 col-form-label text-rigth"></label>
                  <button type="button" className="btn btn-dark" onClick={() => gerarRelatorio()}>Gerar Relatório</button>
                </div>

              </>
            )}

          </form>

        </Card.Body>
      </Card>

      <Footer />
    </Container>
  );
}

export default connect(state => ({
  escola: state.escola,
  sistema: state.sistema,
  usuario: state.usuario,
  periodo: state.periodo,
}))(Relatorios);