
import styled from 'styled-components';
import fundo from '../../assets/fundo.jpg';

export const Section = styled.div`
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  
  padding-top: 40px;

  .button-login{
    width: 140px;
  }

  .modal-lg, .modal-xl {
      max-width: 90%!important;
  }

  .banner-sec {
      background: url(${fundo}) no-repeat;
      background-size: cover;
      min-height: 480px;
      border-radius: 0 10px 10px 0;
      padding: 0;

      @media screen and (max-width: 540px){
        display: none;
      }
  }


  .container {
      background: #fff;
      border-radius: 10px;
      box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
      
      @media screen and (max-width: 540px){
        width: 90%;
      }
  }

  .carousel-inner {
    border-radius: 0 10px 10px 0;
  }

  .carousel-caption {
    text-align: left;
    left: 5%;
  }

  .login-sec {
    padding: 20px 20px;
  }

  .login-sec .copy-text {
    display: flex;
    width: 100%;
    bottom: 20px;
    font-size: 13px;
    justify-content: center;
    
  }
  .login-sec > .copy-text > .fa{ 
    margin-left: 5px;
    margin-right: 5px;
  }

  .login-sec .copy-text i {
    color: #FEB58A;
  }

  .login-sec .copy-text a {
    color: #E36262;
  }

  .login-sec h2 {
    margin-bottom: 30px;
    font-weight: 800;
    font-size: 30px;
    color: #DE6262;
  }

  .login-sec h2:after {
    content: " ";
    width: 100px;
    height: 5px;
    background: #FEB58A;
    display: block;
    margin-top: 20px;
    border-radius: 3px;
    margin-left: auto;
    margin-right: auto
  }

  .btn-login {
    background: #DE6262;
    color: #fff;
    font-weight: 600;
  }

  .banner-text {
    width: 90%;
    position: static;
    bottom: 40px;
    padding: 10px;
    background-color: #DE6262 !important;
    border-radius: 8px;
  }

  .banner-text h2 {
    color: #fff;
    font-weight: 600;
  }

  .banner-text h2:after {
    content: " ";
    width: 100px;
    height: 5px;
    background: #FFF;
    display: block;
    margin-top: 20px;
    border-radius: 3px;
  }

  .banner-text p {
    color: #fff;
  }

  .btn-troca-escola {
    margin-left: 10px;
    padding: 3px;
    cursor: pointer;
  }

  .carousel-caption{
    right: 0px!important;
  }

  .text-small{
    font-size: 12px!important;
  }

  // COOKIES CSS
  .box-cookies.hide {
    display: none !important;
  }

  .box-cookies {
    position: fixed;
    background: rgba(0, 0, 0, .9);
    width: 100%;
    z-index: 998;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
  }

    
  @media screen and (max-width: 600px) {
    .box-cookies {
      flex-direction: column;
    }
  }
`;
