import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import pt from 'date-fns/locale/pt-BR';
import {format, startOfDay, isBefore, endOfDay} from 'date-fns';

import * as yup from 'yup';

import variaveis from '../../../services/variaveis';
import Menuusuario from '../../../componentes/MenuFuncinario';
import Footer from '../../../componentes/Footer/index';
import api from '../../../services/api';
import { Container, TableNota, Botoes } from './styles';
import { useAlert } from 'react-alert';

function FrequenciaLancamentoGlobal({ history, sistema, escola, usuario, periodo }) {

  const alert = useAlert();
  const schema = yup.object({
    turma: yup.string().min(1).required(),
    disciplina: yup.string().min(1).required(),
    materia: yup.string(),
    data: yup.string().min(9).required(),
    aulas: yup.string().min(1).required(),
  });

  const date = startOfDay(new Date());
  const [datalimite, setDataLimite] = useState('');
  const [data, setData] = useState(format(date, 'yyyy-MM-dd'));
  const [passouDataLimite, setPassouDataLimite] = useState(false);
  
  const [lista, setLista] = useState([]);
  const [turmas, setTurmas] = useState([]);
  const [turma, setTurma] = useState(null);
  const [turmaDetalhe, setTurmaDetalhe] = useState(null);
  const [dados, setDados] = useState({
    turma: '',
    disciplina: '',
    materia: '',
    data: format(date, 'yyyy-MM-dd'),
    aulas: 1,
    mes: format(date, 'MM'),
  });

  // eslint-disable-next-line
  const [dataPick, setDataPick] = useState(startOfDay(date));
  
  const CustomDatePciker = React.forwardRef((props, ref) => (
      <button type="button" className="form-control" onClick={props.onClick} >
          {props.value}
      </button>
  ));  

  function getDataLimite(detalhe) {
    let datalimite = date;
    switch (date.getMonth()+1) {
      case 1:
          datalimite = detalhe.dat01;
        break;
    
      case 2:
          datalimite = detalhe.dat02;
        break;

      case 3:
          datalimite = detalhe.dat03;
        break;
      
      case 4:
          datalimite = detalhe.dat04;
        break;
      
      case 5:
          datalimite = detalhe.dat05;
        break;

      case 6:
          datalimite = detalhe.dat06;
        break;
    
      case 7:
          datalimite = detalhe.dat07;
        break;
        
      case 8:
          datalimite = detalhe.dat08;
        break;
      
      case 9:
          datalimite = detalhe.dat09;
        break;

      case 10:
          datalimite = detalhe.dat10;
        break;

      case 11:
          datalimite = detalhe.dat11;
        break;

      case 12:
          datalimite = detalhe.dat12;
        break;

      default:
        break;      
    }

    return datalimite ? startOfDay(new Date(datalimite + ' 00:00:00')) : null;
  }

  // carrega turmas
  useEffect(() => { 
    async function load(){
      let url = '/frequenciadia/turmas/professor/global';

      if (usuario.tipo === 'ADMIN')
        url = '/frequenciadia/turmas/admin/global';

      if (usuario.tipo === 'COORD')
        url = '/frequenciadia/turmas/coord/global';

      const result = await api.get(url, {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          guerra: usuario.guerra,
        },
      });      

      if (result.data){
        const turmas_ = result.data.filter(item => item.modfre == '1');
        setTurmas(turmas_);
      }
    }

    load();
  }, [usuario, escola, periodo])

  // carrega lista de alunos
  useEffect(() => {
    setLista([]);
    async function load() {

      const frequencia_ = await api.get(turma.tiplan === '2' ? '/faltasmesglobal/listaalunos' : '/frequenciaglobal/alunos', {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          turma: dados.turma,
          discip: '',
          materia: '',
          data: dados.data,
          mes: dados.mes,
        },
      });
      
      if (frequencia_.data){
        const lista_ = frequencia_.data.map(item => {
          if (turma.tiplan === '3') {
            item.checked = item.freq == '0' ? true : false; 
            item.freq = item.freq == '' ? 0 : item.freq;
          }
          else
            item.faltas = item?.faltas == '' ? '0' : item.faltas;
          return item;
        })
        setLista(lista_);
      }
    }

    if (dados.turma)
      load();
    else
    {
      setLista([]);
      setTurmaDetalhe([]);
      setDataLimite('');
      setPassouDataLimite(false);
    }

  }, [dados.data, dados.turma, dados.mes]);


  function inputChange(e){
    if (e.target.name === 'turma'){
      const reg = turmas.find(t => t.turma === e.target.value)
      setTurma(reg);
      setDados({...dados, turma: e.target.value});
    }

    if (e.target.name === 'mes'){
      setDados({...dados, mes: e.target?.value});
    }
  }

  function inputDateChange(e){
    setDataPick(e);
    setData(e);
    setDados({...dados, data: format(e, 'yyyy-MM-dd')});
  }

  async function gravar(){
    if (!schema.isValid())
    {
      alert.error('Preencha os campos obrigatórios');
    }else{

      let dados_ = dados;
      dados_.lista = lista;
      dados_.codigo= escola.codigo;
      dados_.escola= periodo.escola;
      dados_.ano= periodo.ano;
      dados_.seqano= periodo.seqano;
      dados_.guerra= usuario.guerra;

      try{

        if (turma.tiplan === '2')
        {
          const result = await api.post('/faltasmes/gravar', dados_);
          const log = await api.post('/faltasmes/gravar/log', dados_);
        }else{
          const result = await api.post('/frequenciadia/gravar', dados_);
          const log = await api.post('/frequenciadia/gravar/log', dados_);
        }

        alert.success('Dados gravados com sucesso!');
      }catch (error) {
        alert.error('Erro ao gravar os dados!');
        console.log(error);
      }
    }

  }

  function handleInputFalta(e){
    const {id} = e.target;
    let lista_ = lista.map((item, index) => {
      if (index == id)
      {
        item.checked = !item.checked;
        item.freq = item.checked ? '0' : '1';
      }

      return item;
    });
    setLista(lista_);
  }

  const handleFalta = (value, item) => {
    let lista_ = lista.map((i) => {
      if (i.matric === item.matric) {
        i.faltas = value;
      }
      return i;
    });
    setLista(lista_);
  }

  return (
    <Container>
      <Menuusuario history={history} />
      <Card className="card-main">
        <Card.Body>
          <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">Lançamento de Faltas / Global</li>
              </ol>
          </nav>          
          
          <hr />          
          <form>
            <div className="form-row">
              <div className="form-group col-md-8">
                <label>Turma</label>
                <select className="form-control" name="turma" defaultValue={dados.turma} onChange={inputChange} >
                  <option value="">...</option>
                  {turmas && turmas.map(item => 
                    <option key={item.turma} value={item.turma}>{item.turma} - {item.destur}</option>
                  )}                
                </select>
              </div>
              {turma?.tiplan === '3' ? (
              <div className="form-group col-md-3">
                <label>Data</label>
                <DatePicker
                  name="data"
                  dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    selected={dataPick}
                    onChange={inputDateChange}
                    customInput={<CustomDatePciker />}
                    locale={pt} />
                </div>
              ) : (
                <div className="form-group col-md-3">
                  <label>Mês</label>
                  <select className="form-control" name="mes" defaultValue={dados?.mes} onChange={inputChange} >
                    {variaveis?.meses.map(item => 
                      <option key={item.mes} value={item.mes_}>{item.des}</option>
                    )}
                  </select>
                </div>
              )}
                {/* <div className="form-group col-md-1">
                  <label>Aulas</label>
                  <input type="text" disabled maxLength={1} className="form-control" name="aulas" onChange={inputChange} value={dados.aulas}/>
                </div> */}
            </div>          

            <hr />
            {passouDataLimite ? (
              <div className="alert alert-danger" role="alert">
                Prazo para lançamento das faltas expirou em <strong>({datalimite})</strong>.
              </div>
            ): (
              datalimite && (
                <div className="alert alert-success" role="alert">
                  Prazo para lançamento das faltas expira em <strong>({datalimite})</strong>.
                </div>
              )
            )}

            {sistema.isMobile ? (
              <TableNota className="table">
                <thead className="thead-dark">             
                  <tr>
                    {/* <th className="column-ord header-sort">Ord.</th> */}
                    {/* <th className="column-matric header-sort">Matrícula</th> */}
                    <th className="column-nome header-sort">Nome</th>
                    <th className="column-faltas">{turma?.tiplan === '3' ? 'Presente' : 'Faltas'}</th>
                  </tr>
                </thead>
                <tbody>  
                  {lista.length > 0 ? (
                    lista.map((item, index) => 
                      <tr key={index} className="line-focus">
                        {/* <td>{item.matric}</td> */}
                        <td>{item.nome}</td>
                        <td>
                        {turma?.tiplan === '3' ? (
                          <div className="switchToggle">
                            <input type="checkbox" checked={item.checked} id={index} onChange={handleInputFalta}/>
                            <label htmlFor={index}>Toggle</label>
                          </div>
                        ) : (
                          <input style={{minWidth: 70}} type="number" maxLength={2} className="form-control" name="faltas" onChange={(e) => handleFalta(e.target.value, item)} value={item.faltas}/>
                        )}
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr key={1}>
                      <td className="linha-sem-dados" colSpan={4}>Nenhuma informação</td>
                    </tr>
                    )}                                   
                </tbody>
              </TableNota>
            ) : (
              <TableNota className="table">
                <thead className="thead-dark">             
                  <tr>
                    {/* <th className="column-ord header-sort">Ord.</th> */}
                    <th className="column-matric header-sort">Matrícula</th>
                    <th className="column-nome header-sort">Nome</th>
                    <th className="column-faltas">{turma?.tiplan === '3' ? 'Presente' : 'Faltas'}</th>
                  </tr>
                </thead>
                <tbody>  
                  {lista.length > 0 ? (
                    lista.map((item, index) => 
                      <tr key={index} className="line-focus">
                        <td>{item.matric}</td>
                        <td>{item.nome}</td>
                        <td>
                        {turma?.tiplan === '3' ? (
                          <div className="switchToggle">
                            <input type="checkbox" checked={item.checked} id={index} onChange={handleInputFalta}/>
                            <label htmlFor={index}>Toggle</label>
                          </div>
                        ) : (
                          <input type="number" style={{minWidth: 70}}  maxLength={2} className="form-control" name="faltas" onChange={(e) => handleFalta(e.target.value, item)} value={item.faltas}/>
                        )}
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr key={1}>
                      <td className="linha-sem-dados" colSpan={3}>Nenhuma informação</td>
                    </tr>
                    )}                                   
                </tbody>
              </TableNota>              
            )}
          </form>
          
          <hr />
          {(lista.length > 0 && !passouDataLimite)&& (
            <Botoes>    
              <button type="button" className="btn btn-success" onClick={gravar}>Gravar</button>            
            </Botoes>
          )}
        </Card.Body>
      </Card>

      <Footer />
    </Container>
  );
}

export default connect(state => ({
  escola: state.escola,
  sistema: state.sistema,
  usuario: state.usuario,
  periodo: state.periodo,
}))(FrequenciaLancamentoGlobal);