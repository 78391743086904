import React, { useState, useEffect } from 'react';
import { Container, TableTransferencia, Botoes, Formula } from './styles';
import { Card, Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import {format, startOfDay, isBefore} from 'date-fns';
import {FaSearchPlus} from 'react-icons/fa';

import Menuusuario from '../../../componentes/MenuFuncinario';
import Footer from '../../../componentes/Footer/index';
import Pagination from '../../../componentes/Pagination';

import api from '../../../services/api';
import variaveis from '../../../services/variaveis';
import { useAlert } from 'react-alert';

function FrequenciaConsulta({ history, sistema, escola, usuario, periodo }) {

  const alert = useAlert();
  const [show, setShow] = useState(false); // alterar para true
  const [regDetalhes, setRegDetalhes] = useState(null);
  const [detalhes, setDetalhes] = useState([]);
  const [lista, setLista] = useState([]);
  const [listaFiltro, setListaFiltro] = useState([]);
  const [meses] = useState(variaveis.meses);
  const [filtro, setFiltro] = useState({
    tipo: 'FD',
    mes: format(new Date(), 'MM'),
    situacao: ''
  });

  // Inicio Paginação
  const [listaPagina, setListaPagina] = useState([]);
  const [perPage, setPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [update, setUpdate] = useState(false);
  
  function paginate(event, selectedPage) {
    event.preventDefault();
    setCurrentPage(selectedPage);
  };
  // Fim Paginação

  function closeModal() {
    setShow(false);
  }

  async function showDetalhes(reg) {
    setShow(true);

    const result = await api.get('frequenciadia/consulta/detalhes', {
      params: {
        codigo: escola.codigo,
        escola: periodo.escola,
        ano: periodo.ano,
        seqano: periodo.seqano,
        turma: reg.turma,
        discip: reg.discip,
        materia: reg.materia,
        mes: filtro.mes,
      }
    });
    
    setRegDetalhes(reg);
    setDetalhes(result.data);
  }

  async function buscar(){
    
    setLista([]);

    let url = '/faltasglobal';

    if (filtro.tipo === 'FM')
    {
      if (usuario.tipo === 'PROF')
        url = '/faltasmes/prof';

      if (usuario.tipo === 'ADMIN')
        url = '/faltasmes/admin';

      if (usuario.tipo === 'COORD')
        url = '/faltasmes/coord';

    }else if (filtro.tipo === 'FD'){

      if (usuario.tipo === 'PROF')
        url = '/faltasdia';

      if (usuario.tipo === 'ADMIN')
        url = '/faltasdia/admin';

      if (usuario.tipo === 'COORD')
        url = '/faltasdia/coord';

    }

    const result = await api.get(url, {
      params: {
        codigo: escola.codigo,
        escola: periodo.escola,
        ano: periodo.ano,
        seqano: periodo.seqano,
        guerra: usuario.guerra,
        situacao: filtro.situacao,
        mes: filtro.mes,
      },
    });

    // 
    
    setLista(result.data);
    setCurrentPage(1);
  };

  async function excluir(item) {
    const result = await api.post('/frequenciadia/excluir', {
        codigo: escola.codigo,
        escola: periodo.escola,
        ano: periodo.ano,
        seqano: periodo.seqano,
        turma: regDetalhes.turma,
        discip: regDetalhes.discip,
        materia: regDetalhes.materia,
        data: item.data
      });
    
    showDetalhes(regDetalhes);
  }

  useEffect(() => {
    
    const indexOfLast = currentPage * perPage;
    const indexOfFirst = indexOfLast - perPage;
    
    let result = [];

    if(lista.length > 0)
      result = lista.slice(indexOfFirst, indexOfLast);

    setListaPagina(result);
  }, [lista, currentPage]);

  return (
    <Container>
      <Menuusuario history={history} />
      <Card className="card-main">
        <Card.Body>
          <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">Consulta de Lançamentos</li>
              </ol>
          </nav>          
          
          <hr />          
          <form>
            <div className="form-row">
              <div className="form-group col-md-3">
                <label>Tipo Frequência</label>
                <select className="form-control" defaultValue={filtro.tipo} onChange={e => setFiltro({...filtro, tipo: e.target.value})}>
                  <option value="FD">Faltas Dia</option>                                 
                  <option value="FM">Faltas Mês</option>                                 
                  <option value="FG">Faltas Global</option>                                 
                </select>
              </div>  
              <div className="form-group col-md-3">
                <label>Mês</label>
                <select className="form-control" defaultValue={filtro.mes} onChange={e => setFiltro({...filtro, mes: e.target.value})}>
                  {meses.map(mes => (
                    <option key={mes.mes} value={mes.mes_}>{mes.des}</option>                                 
                  ))}
                </select>
              </div>  
              <div className="form-group col-md-3">
                <label>Situação</label>
                <select className="form-control" defaultValue={filtro.situacao} onChange={e => setFiltro({...filtro, situacao: e.target.value})}>
                  <option value="">Todos</option>                                 
                  <option value="SL">Sem Lançamentos</option>                                 
                  <option value="FL">Faltas Lançadas</option>                                 
                  <option value="IM">Importadas</option>                                 
                </select>
              </div>                          
            </div>
            <div className="form-row">
              <div className="form-group col-md-2" onClick={() => buscar()}>
                <button type="button" className="btn btn-secondary btn-block">Buscar</button>            
              </div> 
            </div>
            
            <hr />

            <TableTransferencia className="table table-hover">
              <thead className="thead-dark">                               
                <tr>
                  <th className="column-turma">Turma / Disciplina / Matéria</th>
                  <th className="column-data-hora">Ult. Gravação</th>
                  <th className="column-data-hora">Ult. Imp.</th>
                  <th className="column-view"></th>
                </tr>
              </thead>
              <tbody>
                {listaPagina.length > 0 && (listaPagina.map((item, index) => (
                  <tr key={index}>
                    <td className="column-turma">
                    {item.turma} - {item.destur} - {item.desdis} {item.materia && (' - ' + item.materia)}<br />  
                    </td>                    
                    <td className="column-data-hora">
                      { item.data_ultimagravacao ? format(new Date(item.data_ultimagravacao + 'T00:00:00'), 'dd/MM/yyyy') : ' - - '}
                      <br/>
                      { item.data_ultimagravacao && item.hora_ultimagravacao }
                    </td>
                    <td className="column-data-hora">
                      { item.data_ultimaimportacao ? format(new Date(item.data_ultimaimportacao + 'T00:00:00'), 'dd/MM/yyyy') : ' - - '}
                      <br/>
                      { item.data_ultimaimportacao && item.hora_ultimaimportacao }
                    </td>
                    
                    <td className="column-view">
                      {filtro.tipo !== 'FM' && (
                        <FaSearchPlus onClick={() => showDetalhes(item)} size={18} className="icon-view" />
                      )}
                    </td>
                    
                  </tr>               
                )))} 

                {listaPagina.length === 0 && (
                  <tr key={1}>
                    <td className="linha-sem-dados" colSpan={4}>Nenhuma informação</td>
                  </tr>
                )}
              </tbody>
            </TableTransferencia>
            <Pagination perPage={perPage} total={lista.length} paginate={paginate}/>
          </form>

        </Card.Body>
      </Card>
      
      <Modal show={show}>
        {regDetalhes && (
        <Modal.Header >
          <Modal.Title style={{fontSize: 12}}>{regDetalhes.destur} - {regDetalhes.desdis}</Modal.Title>
        </Modal.Header>
        )}
        <Modal.Body>
          <table className="table table-striped table-bordered">
            <thead className="thead-dark">
              <tr>
                <th>Data</th>
                <th className="text-center">Qtd. Aulas</th>
                <th className="text-center">Excluir</th>
              </tr>
            </thead>
            <tbody>
              {detalhes.length > 0 ? (
                detalhes.map(reg => 
                <tr key={reg.data}>
                  <td>{reg.dataf}</td>
                  <td className="text-center" style={{width: 50}}>{reg.qtdaulas}</td>
                  <td className="text-center" style={{width: 50,}}><i onClick={() => excluir(reg)} className="far fa-trash-alt btn-icon"></i></td>
                </tr>
              )) : (
                <tr>
                  <td className="text-center" colSpan={3}>Nenhum dado encontrado.</td>
                </tr>
              )}
            </tbody>
          </table>          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>Fechar</Button>
        </Modal.Footer>
      </Modal>
      <Footer />
    </Container>
  );
}

export default connect(state => ({
  escola: state.escola,
  sistema: state.sistema,
  usuario: state.usuario,
  periodo: state.periodo,
}))(FrequenciaConsulta);